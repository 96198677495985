.navbar {
    .navlink {
        color: rgba(255, 255, 255, 0.7) !important;
        text-decoration: none;
        margin-right: 30px;
    }

    .active {
        color: white !important;
        text-shadow: 0px 0px 7px white;
    }
    font-size: 1rem;
}

@media only screen and (max-width: 600px) {
    .navbar {
        .navlink {
            margin-right: 15px;
        }
        font-size: 0.8rem;
    }
}